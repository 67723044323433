<template>
  <div>
    <div class="row justify-content-between">
      <div class="col-auto"></div>
      <div class="col-auto">
        <button
          @click="$router.push({ name: 'raw-materials-create' })"
          class="btn btn-primary"
        >
          <i class="bi bi-plus-square"></i>
          Créer
        </button>
        <button @click="refresh()" class="btn btn0 btn-secondary">
          Actualiser
        </button>
      </div>
    </div>

    <table class="table table-striped table-hover">
      <thead>
        <tr>
          <th scope="col"></th>
          <th scope="col">Référence</th>
          <th scope="col">Fournisseur</th>
          <th scope="col">Date</th>
          <th scope="col">Montant Total</th>
          <th scope="col">Total Qté</th>
          <th scope="col">Total Qté (U*)</th>
          <th scope="col">M. Payé</th>
          <th scope="col">M. à Régler</th>
          <th scope="col">Créer par</th>
        </tr>
      </thead>

      <tbody>
        <tr v-for="(raw_material, index) in raw_materials" :key="index++">
          <td>
            {{ index }}
          </td>
          <td>
            {{ raw_material.reference }}
          </td>
          <td>
            <span v-if="raw_material.supplier">
              {{ raw_material.supplier.society }}
            </span>
          </td>
          <td>{{ raw_material.date }}</td>
          <td class="text-info">
            <span
              v-if="raw_material.totalPriceTTC != null"
              class="bg-primary px-2 rounded"
            >
              {{ raw_material.totalPriceTTC }}
            </span>
          </td>
          <td class="text-warning">
            <span v-if="raw_material.totalQuantity != null">
              {{ raw_material.totalQuantity }}
            </span>
          </td>

          <td class="text-warning">
            <span v-if="raw_material.totalQuantityUnit != null">
              {{ raw_material.totalQuantityUnit }}
            </span>
          </td>
          <td class="text-success">
            <span
              v-if="raw_material.amountPaid != null"
              class="bg-success px-2 rounded"
            >
              {{ raw_material.amountPaid }}
            </span>
          </td>
          <td class="text-danger">
            <span
              v-if="raw_material.amountUnpaid != null"
              class="bg-warning px-2 rounded"
            >
              {{ raw_material.amountUnpaid }}
            </span>
          </td>
          <td>
            <!-- {{ raw_material.user_reference }}
            <br /> -->
            <span v-if="raw_material.user">
              {{ raw_material.user.name }}
            </span>
          </td>
          <td class="p-0 m-0">
            <button
              @click="
                $router.push({
                  name: 'raw-materials-details',
                  params: { reference: raw_material.reference },
                })
              "
              class="btn"
            >
              <i class="bi bi-info-square fs-5"></i>
            </button>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
export default {
  data() {
    return {
      search_value: "",
      addedToday: false,
      sortValue: "",
    };
  },
  computed: {
    ...mapGetters("rawMaterial", {
      raw_materials: "getAll",
    }),
  },
  mounted() {
    this.$store.dispatch("rawMaterial/getAll");

    // setInterval(() => {
    //   this.$store.dispatch("purchase/getAll");
    // }, 3000);
  },
  methods: {
    async search(value) {
      return await this.$store.commit("rawMaterial/search", value);
    },
    async refresh() {
      return await this.$store.dispatch("rawMaterial/getAll");
    },

    async listRawMaterials(checkbox) {
      if (checkbox == true) {
        console.log(checkbox);
        return await this.$store.commit("rawmMaterial/getTodays");
      } else {
        return await this.$store.dispatch("rawMaterial/getRawMaterials");
      }
    },
    async sort(value) {
      return await this.$store.commit("rawMaterial/sort", value);
    },
  },
};
</script>
